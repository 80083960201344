import { HTTP } from './http'

class Auth {
    login(credentials) {
        return HTTP.post('/auth/token', credentials)
    }
    logout() {
        return HTTP.post('/auth/revoke-access-token')
    }
    isAuthorized() {
        return HTTP.get('/is-authorized')
    }
}

export default new Auth();