<template>
    <component :is="layout"></component>
</template>

<script>
import AppLayout from './components/layouts/App'
import LoginLayout from './components/layouts/Login'
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
    setup () {
        const store = useStore()
        console.log(store.getters.layout, 'store.getters.layout Worked')
        return {
            layout: computed(() => store.getters.layout)
        }
    },
    components: {
        AppLayout,
        LoginLayout
    }
}
</script>

<style lang="scss">
</style>
