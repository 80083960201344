import Credentials from '@/api/credentials'

const credentialsModule = {
    state: {
        credentials: [],
        credential: {
            url: 'url',
            name: 'name',
            category_id: 1,
            username: 'username',
            password: 'password',
            note: 'note'
        }
    },

    getters: {
        getCredentials: (state) => {
            return state.credentials
        },
        getCredential: (state) => {
            return state.credential
        }
    },

    mutations: {
        setCredentials (state, credentials) {
            state.credentials = credentials
        },
        setCredential (state, credential) {
            state.credential = credential
        },
        addCredentials (state, credential) {
            state.credentials.push(credential)
        },
        updateCredentials (state, credential) {
            const index = state.credentials.findIndex(item => item.uuid === credential.uuid)
            if ( index !==  -1 ) {
                state.credentials[index] = credential
            } else {
                console.log('error mutation -> updateCredentials:', credential)
            }
        },
        updateCredential (state, property) {
            state.credential[property.key] = property.value
        },
        removeCredential (state, uuid) {
            const index = state.credentials.findIndex(item => item.uuid === uuid)
            if ( index !==  -1 ) {
                state.credentials.splice(index, 1)
            } else {
                console.log('error removeMutation -> removeCredential:', uuid)
            }
        }
    },

    actions: {
        fetchCredentials({commit}) {
            return Credentials.getAll()
                .then( resp => {
                    commit('setCredentials', resp.data)
                })
        },
        fetchCredential({commit}, uuid) {
            return Credentials.get(uuid)
                .then( resp => {
                    commit('setCredential', resp.data)
                    return resp
                })
        },
        addCredential({commit}, credential) {
            return Credentials.add(credential)
                .then( resp => {
                    commit('addCredentials', resp.data.secret)
                    return resp
                })
        },
        updateCredential({commit}, credential) {
            return Credentials.update(credential)
                .then( resp => {
                    commit('updateCredentials', resp.data.secret)
                    return resp
                })
        },
        removeCredential({commit}, uuid) {
            return Credentials.remove(uuid)
                .then( resp => {
                    commit('removeCredential', uuid)
                    return resp
                })
        },
        importFromFile({}, formData) {
            return Credentials.importFromFile(formData)
        }
    }
}

export default credentialsModule