<template>
    <div class="container d-flex flex-column justify-content-between vh-100">
        <div class="row justify-content-center mt-5">
            <div class="col-xl-5 col-lg-6 col-md-10">
                <div class="card">
                    <div class="card-header bg-primary">
                        <div class="app-brand">
                            <a href="/index.html">
                                <svg class="brand-icon" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid" width="30" height="33" viewBox="0 0 30 33">
                                    <g fill="none" fill-rule="evenodd">
                                        <path class="logo-fill-blue" fill="#7DBCFF" d="M0 4v25l8 4V0zM22 4v25l8 4V0z"></path>
                                        <path class="logo-fill-white" fill="#FFF" d="M11 4v25l8 4V0z"></path>
                                    </g>
                                </svg>
                                <span class="brand-name">Sleek Dashboard</span>
                            </a>
                        </div>
                    </div>
                    <div class="card-body p-5">
                        
                        <div v-if="error" class="alert alert-danger" role="alert">
                            <span v-for="(e, index) in error" :key="index">
                                <span v-if="e.constructor.name === 'Array'">
                                    <span v-for="(er, index) in e" :key="index">{{ er }}</span>
                                </span>
                                <span v-else>{{ e }}</span>
                            </span>
                        </div>
                        <h4 class="text-dark mb-5">Sign In</h4>
                        <form @submit.prevent="login">
                            <div class="row">
                                <div class="form-group col-md-12 mb-4">
                                    <input type="email" class="form-control" v-model="email" placeholder="Username">
                                </div>

                                <div class="input-group col-md-12 mb-4">
                                    <input :type="PasswordFieldType" class="form-control" v-model="password" placeholder="Password">
                                    <div class="input-group-append">
                                        <span class="input-group-text cursor-pointer">
                                            <i class="mdi mdi-eye" @click="switchVisibility"></i>
                                        </span>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="d-flex my-2 justify-content-between">
                                        <div class="d-inline-block mr-3">
                                            <label class="control control-checkbox">Remember me
                                                <input type="checkbox">
                                                <div class="control-indicator"></div>
                                            </label>
                                        </div>
                                        <p><a class="text-blue" href="#">Forgot Your Password?</a></p>
                                    </div>
                                    <button type="submit" class="btn btn-lg btn-primary btn-block mb-4">Sign In</button>
                                    <p>Don't have an account yet ?
                                        <a class="text-blue" href="sign-up.html">Sign Up</a>
                                    </p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright pl-0">
            <p class="text-center">© 2018 Copyright Sleek Dashboard Bootstrap Template by
                <a class="text-primary" href="http://www.iamabdus.com/" target="_blank">Abdus</a>.
            </p>
        </div>
    </div>
</template>
<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'

export default {
    setup () {
        const router = useRouter()
        const route = useRoute()
        const store = useStore()
        const email = ref('')
        const PasswordFieldType = ref('password')
        const password = ref('')
        const error = ref(null)
        function login() {
            // some axios styff
            if (email.value.length > 1 && password.value.length > 1) {                
                store.dispatch('login', { email: email.value, password: password.value })                
                .then((resp) => {
                    if (route.query.redirect) {
                        router.push(route.query.redirect)
                    } else {
                        router.push({ name: "Home" })
                    }
                })
                .catch((e) => {
                    if (e.response) {
                        error.value = e.response.data.errors
                    } else {
                        error.value = [e.message]
                    }
                })    
            } else {
                error.value = ['email or pass are invalid']
            }
        }

        return {
            email,
            password,
            login,
            PasswordFieldType,
            switchVisibility: () => PasswordFieldType.value = PasswordFieldType.value === 'password' ? 'text' : 'password',
            error
        }
    }
}
</script>
<style scoped>
.cursor-pointer {
    cursor: pointer;
}
</style>