<template>
    <div class="card card-default">
        <div class="card-header card-header-border-bottom">
            <h2>{{ name }}</h2>
        </div>
        <div class="card-body">
            <form @submit.prevent="saveForm" class="horizontal-form">
                <div v-if="errorApi" class="alert alert-danger" role="alert">
                    <span>{{ errorApi }}</span>
                </div>

                <div class="form-group row">
                    <div class="col-12 col-md-3 text-right">
                        <label>URL</label>
                    </div>
                    <div class="col-12 col-md-9">
                        <input v-model="url" type="text" class="form-control" :class="{ 'is-invalid': error.url }" placeholder="Enter url">
                        <small class="form-text text-muted">(https://google.com).</small>
                        <div v-if="error.url" class="invalid-feedback">
                            <span v-for="(err, index) in error.url" :key="index">{{ err }}</span>                            
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-12 col-md-3 text-right">
                        <label>Name</label>
                    </div>
                    <div class="col-12 col-md-9">
                        <input v-model="name" type="text" class="form-control" :class="{ 'is-invalid': error.name }" placeholder="Enter name">
                        <div v-if="error.name" class="invalid-feedback">
                            <span v-for="(err, index) in error.name" :key="index">{{ err }}</span>                            
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-12 col-md-3 text-right">
                        <label>Category</label>
                    </div>
                    <div class="col-12 col-md-9">
                        <select class="form-control" id="exampleFormControlSelect12" v-model="category_id">
                            <option v-for="category in getCategories" :key="category.id" :value="category.id">{{category.name}}</option>
                        </select>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-12 col-md-3 text-right">
                        <label>Username</label>
                    </div>
                    <div class="col-12 col-md-9">
                        <input v-model="username" type="text" class="form-control" :class="{ 'is-invalid': error.username }" placeholder="Enter username">
                        <div v-if="error.username" class="invalid-feedback">
                            <span v-for="(err, index) in error.username" :key="index">{{ err }}</span>                            
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-12 col-md-3 text-right">
                        <label>Password</label>
                    </div>
                    <div class="input-group col-12 col-md-9">
                        <input v-model="password" :type="PasswordFieldType" class="form-control" :class="{ 'is-invalid': error.password }" placeholder="Enter password">
                        <div class="input-group-append">
                            <span class="input-group-text cursor-pointer" @click="switchVisibility">
                                <i class="mdi mdi-eye"></i>
                            </span>
                        </div>
                        <div v-if="error.password" class="invalid-feedback">
                            <span v-for="(err, index) in error.password" :key="index">{{ err }}</span>                            
                        </div>
                    </div>
                </div>
                    
                <div class="form-group row">
                    <div class="col-12 col-md-3 text-right">
                        <label>Note</label>
                    </div>
                    <div class="col-12 col-md-9">
                        <textarea v-model="note" class="form-control" rows="3" :class="{ 'is-invalid': error.note }"></textarea>
                        <div v-if="error.note" class="invalid-feedback">
                            <span v-for="(err, index) in error.note" :key="index">{{ err }}</span>                            
                        </div>
                    </div>
                </div>

                <div class="form-footer pt-5 border-top">
                    <button type="submit" class="mb-1 mr-1 btn btn-primary">
                        <i class="mdi mdi-content-save-outline mr-1 mdi-18px"></i> Save
                    </button>
                    <button @click="remove" type="button" class="mb-1 btn btn-danger">
                        <i class="mdi mdi-delete-forever-outline mr-1 mdi-18px"></i> Delete
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import { inject, watch, ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
    props: {
        uuid: String
    },
    setup(props) {
        const router = useRouter()

        const error = ref('')
        const errorApi = ref('')
        const store = useStore()
        const PasswordFieldType = ref('password')

        const getCategories = computed(() => {
            return store.getters.getCategories()
        })

        const toast = inject('toast')

        const fetchCredential = async () => await store.dispatch('fetchCredential', props.uuid)
                .catch((e) => { 
                    toast.error('Secret not found!!', {
                        position: "top",
                        duration: 10000
                    })
                    router.replace({ name: 'Credentials' })
                })

        onMounted( async () => {
            await store.dispatch('fetchCategories')
            fetchCredential()

            var d = new Date(store.getters.getCredential.updated_at)
            console.log(store.getters.getCredential.updated_at,' : ', d.toString())
        })

        watch(() => props.uuid, () => {
            fetchCredential()
        })
        
        const url = computed({
            get: () => store.getters.getCredential.url,
            set: val => store.commit('updateCredential', { key: 'url', value: val })
        })
        const name = computed({
            get: () => store.getters.getCredential.name,
            set: val => store.commit('updateCredential', { key: 'name', value: val })
        })
        const category_id = computed({
            get: () => store.getters.getCredential.category_id,
            set: val => store.commit('updateCredential', { key: 'category_id', value: val })
        })
        const username = computed({
            get: () => store.getters.getCredential.username,
            set: val => store.commit('updateCredential', { key: 'username', value: val })
        })
        const password = computed({
            get: () => store.getters.getCredential.password,
            set: val => store.commit('updateCredential', { key: 'password', value: val })
        })
        const note = computed({
            get: () => store.getters.getCredential.note,
            set: val => store.commit('updateCredential', { key: 'note', value: val })
        })

        const saveForm = () => {
            store.dispatch('updateCredential', { uuid: props.uuid, secret: {
                url: url.value?.indexOf('http') > -1 || url.value?.length < 1 ? url.value : 'https://' + url.value,
                name: name.value,
                category_id: category_id.value,
                username: username.value,
                password: password.value,
                note: note.value
            }})
            .then((resp) => {
                toast.success('Secret saved')
                router.push({ name: "Credentials" })
            })
            .catch((e) => {
                    console.log('vi er her: ', e)
                if (e.response) {
                    if (e.response.data.errors) {
                        console.log('vi er her: ', e.response.data.errors)
                        error.value = e.response.data.errors
                    } else {
                        errorApi.value = e.response.data.error
                    }
                } else {
                    errorApi.value = [e.message]
                }
            })
        }

        const remove = () => {
            console.info('you are about to remove this item,')
            if ( confirm('This will permanently delete the "'+ name.value +'" from your Vault.') ) {

                store.dispatch('removeCredential', props.uuid)
                    .then((resp) => {
                        console.log('Successfully Deleted: ', resp.data)
                        toast.success('Successfully Deleted', {
                            position: "top",
                            duration: 5000
                        })
                        router.push({ name: 'Credentials' })
                    })
                    .catch((e) => {
                        console.log('Failed to delete secret: ', e.message)
                    })
            }
        }

        return {
            url,
            name,
            getCategories,
            category_id,
            username,
            password,
            note,
            error,
            errorApi,
            saveForm,
            PasswordFieldType,
            switchVisibility: () => PasswordFieldType.value = PasswordFieldType.value === 'password' ? 'text' : 'password',
            remove
        }
    },
}
</script>
<style scoped>
.cursor-pointer {
    cursor: pointer;
}
</style>