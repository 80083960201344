import Categories from '@/api/categories'

const categoriesModule = {
    state: {
        categories: [],
        category: {
            name: '',
            logo: ''
        }
    },

    getters: {
        getCategories: (state) => {
            return (sortDirection = 'asc', sortField = 'id') => {
                return state.categories.sort((a, b) => {
                    const direction = sortDirection === 'asc' ? 1 : -1
                    a = new Date(a[sortField])
                    b = new Date(b[sortField])
                    const results = a > b ? -1 : a < b ? 1 : 0
                    return results * direction
                })
            }
        },
        getCategoryById: (state) => {
            return (id) => state.categories.filter((c) => c.id === id)[0]
        },
        getCategory: (state) => {
            return state.category
        }
    },

    mutations: {
        setCategories(state, categories) { state.categories = categories },
        setCategory(state, category) { state.category = category },
        addCategory(state, category) { state.categories.push(category) },
        updateCategories(state, category) {
            const index = state.categories.findIndex(item => item.id === category.id)
            if ( index !== -1 ) {
                state.categories[index] = category
            } else {
                console.log('error mutation -> updateCategories:', category)
            }
        },
        updateCategory (state, property) {
            state.category[property.key] = property.value
        },
        removeCategory(state, id) {
            const index = state.categories.findIndex(item => item.id === id)
            if ( index !==  -1 ) {
                state.categories.splice(index, 1)
            } else {
                console.log('error removeMutation -> removeCategory:', id)
            }
        }
    },

    actions: {
        fetchCategories({commit}) {
            return Categories.getAll()
                .then( resp => {
                    commit('setCategories', resp.data)
                    return resp
                })
        },
        fetchCategory({commit}, id) {
            return Categories.get(id)
                .then( resp => {
                    commit('setCategory', resp.data)
                    return resp
                })
        },
        addCategory({commit}, category) {
            return Categories.add(category)
                .then( resp => {
                    commit('addCategory', resp.data)
                    return resp
                })
        },
        updateCategory({commit}, category) {
            return Categories.update(category)
                .then( resp => {
                    commit('updateCategories', resp.data)
                    return resp
                })
        },
        removeCategory({commit}, id) {
            return Categories.remove(id)
                .then( resp => {
                    commit('removeCategory', id)
                    return resp
                })
        }

    }
}
export default categoriesModule