
import { DateTime } from "luxon"

export default function MyDatetimeObject() {

    const newUTCDate = (utcDate) => new Date(Date.UTC(
            utcDate.getFullYear(),
            utcDate.getMonth(),
            utcDate.getDate(),
            utcDate.getHours(),
            utcDate.getMinutes()
        ));

    const relativeDate = (dateString=false) => {
        if (dateString) { dateString }
        const utcDate = new Date(dateString);
        return DateTime
            .fromJSDate(newUTCDate(utcDate))
            .setZone('Europe/Copenhagen')
            .toRelative();
    }

    const toLocaleString = (dateString=false) => {
        if (dateString) { dateString }
        const utcDate = new Date(dateString);
        return DateTime
            .fromJSDate(newUTCDate(utcDate))
            .setZone('Europe/Copenhagen')
            .toString("full");
    }

    return {
        relativeDate,
        toLocaleString
    }
}