<template>
    <header class="main-header" id="header">
        <nav class="navbar navbar-static-top navbar-expand-lg">
            <!-- Sidebar toggle button -->
            <button id="sidebar-toggler" class="sidebar-toggle">
            <span class="sr-only">Toggle navigation</span>
            </button>
            <!-- search form -->
            <div class="search-form d-none d-lg-inline-block">
                <div class="input-group">
                    <button type="button" name="search" id="search-btn" class="btn btn-flat">
                        <i class="mdi mdi-magnify"></i>
                    </button>
                    <input
                        type="text"
                        v-model="searchKey"
                        @keyup="handleSearch"
                        name="query"
                        id="search-input"
                        class="form-control"
                        placeholder="search here ..."
                        autofocus autocomplete="off" />
                </div>
                <div id="search-results-container">
                    <ul id="search-results">
                        <li v-for="(item, index) in credentials" :key="index">
                            <div class="link">
                                <a @click="resetSearch(item)" href="javascript:void(0)">
                                    {{ item.name }}
                                </a>
                            </div>
                            <div class="location">{{ item.category }}</div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="navbar-right ">
            <ul class="nav navbar-nav">
                <li class="github-link mr-3">
                    <button @click="openAddNewModal" class="mb-1 btn btn-outline-primary">
                        <i class="mdi mdi-star-outline mr-1"></i>
                        Add new
                    </button>
                </li>
                <li class="dropdown notifications-menu">
                <button class="dropdown-toggle" data-toggle="dropdown">
                    <i class="mdi mdi-bell-outline"></i>
                </button>
                <ul class="dropdown-menu dropdown-menu-right">
                    <li class="dropdown-header">You have 5 notifications</li>
                    <li>
                    <a href="#">
                        <i class="mdi mdi-account-plus"></i> New user registered
                        <span class=" font-size-12 d-inline-block float-right"><i class="mdi mdi-clock-outline"></i> 10 AM</span>
                    </a>
                    </li>
                    <li>
                    <a href="#">
                        <i class="mdi mdi-account-remove"></i> User deleted
                        <span class=" font-size-12 d-inline-block float-right"><i class="mdi mdi-clock-outline"></i> 07 AM</span>
                    </a>
                    </li>
                    <li>
                    <a href="#">
                        <i class="mdi mdi-chart-areaspline"></i> Sales report is ready
                        <span class=" font-size-12 d-inline-block float-right"><i class="mdi mdi-clock-outline"></i> 12 PM</span>
                    </a>
                    </li>
                    <li>
                    <a href="#">
                        <i class="mdi mdi-account-supervisor"></i> New client
                        <span class=" font-size-12 d-inline-block float-right"><i class="mdi mdi-clock-outline"></i> 10 AM</span>
                    </a>
                    </li>
                    <li>
                    <a href="#">
                        <i class="mdi mdi-server-network-off"></i> Server overloaded
                        <span class=" font-size-12 d-inline-block float-right"><i class="mdi mdi-clock-outline"></i> 05 AM</span>
                    </a>
                    </li>
                    <li class="dropdown-footer">
                    <a class="text-center" href="#"> View All </a>
                    </li>
                </ul>
                </li>
                <!-- User Account -->
                <li class="dropdown user-menu">
                <button href="#" class="dropdown-toggle nav-link" data-toggle="dropdown">
                    <img src="/assets/img/user/user.png" class="user-image" alt="User Image" />
                    <span class="d-none d-lg-inline-block">{{ username }}</span>
                </button>
                <ul class="dropdown-menu dropdown-menu-right">
                    <!-- User image -->
                    <li class="dropdown-header">
                    <img src="/assets/img/user/user.png" class="img-circle" alt="User Image" />
                    <div class="d-inline-block">
                        {{ username }} <small class="pt-1">abdus@gmail.com</small>
                    </div>
                    </li>

                    <li>
                        <a href="profile.html">
                            <i class="mdi mdi-account"></i> My Profile
                        </a>
                    </li>
                    <li>
                        <a href="email-inbox.html">
                            <i class="mdi mdi-email"></i> Message
                        </a>
                    </li>
                    <li>
                    <a href="#"> <i class="mdi mdi-diamond-stone"></i> Projects </a>
                    </li>
                    <li>
                    <a href="#"> <i class="mdi mdi-settings"></i> Account Setting </a>
                    </li>

                    <li class="dropdown-footer">
                        <a @click="logout" href="javascript:void(0)"> <i class="mdi mdi-logout"></i> Log Out </a>
                    </li>
                </ul>
                </li>
            </ul>
            </div>
        </nav>
    </header>
    <teleport to="body">
        <add-new v-if="showAddNewModal" @close="closeAddNewModal" />
    </teleport>
</template>
<script>
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { computed, ref, onMounted } from 'vue'
import AddNew from '../AddNew.vue'

export default {
    setup() {
        const store = useStore()
        const router = useRouter()
        let searchKey = ref('')

        onMounted(async () => await store.dispatch('fetchCredentials'))

        let showAddNewModal = ref(false)
        const credentials = ref([])

        const handleSearch = (e) => {
            credentials.value = store.getters.getCredentials.filter((item) => {
                return searchKey.value.length >= 2 ? item.name.toLowerCase().includes(searchKey.value.toLowerCase()) : ''
            })
        }

        const resetSearch = (item) => {
            searchKey.value = ''
            credentials.value = []

            router.push({ name: 'CredentialDetails', params: { uuid: item.uuid } })
        }

        const logout = () => {
            store.dispatch('logout')
            .then(() => {
                router.push({ name: "Login" })
            })
        }
        return {
            username: computed(() => store.getters.username),
            logout,
            showAddNewModal,
            openAddNewModal: () => {
                document.body.classList.add("modal-open")
                showAddNewModal.value = true
            },
            closeAddNewModal: (e) => {
                document.body.classList.remove("modal-open")
                showAddNewModal.value = false
            },
            searchKey,
            credentials,
            handleSearch,
            resetSearch
        }
    },

    components: {
        AddNew
    }
}
</script>