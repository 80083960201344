<template>
    <div id="layout" class="wrapper">
        <Aside />
        <div class="page-wrapper">
            <Header />

            <div class="content-wrapper">
                <div class="content">
                    <router-view />
                </div>
            </div>

            <Footer />
        </div>
    </div>
</template>

<script>
import Aside from './Aside.vue'
import Footer from './Footer.vue'
import Header from './Header.vue'

export default {
  setup () {

  },
  components: {
    Aside,
    Footer,
    Header
  }
}
</script>
