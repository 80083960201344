import { HTTP } from '@/api/http'

class Credentials {
    getAll() {
        return HTTP.get('/secrets')
    }
    get(uuid) {
        return HTTP.get('/secrets/' + uuid)
    }
    add(secret) {
        return HTTP.post('/secrets', secret)
    }
    update(payload) {
        return HTTP.put('/secrets/' + payload.uuid, payload.secret)
    }
    remove(uuid) {
        return HTTP.delete('/secrets/' + uuid)
    }
    importFromFile(formData) {
        return HTTP.post('/import-secrets', formData, {})
    }
}

export default new Credentials()