import { createStore, createLogger } from 'vuex'
import authModule from './modules/auth'
import credentialsModule from './modules/credentials'
import categoriesModule from './modules/categories'

const debug = process.env.NODE_ENV !== 'production'

const Store = createStore({

    strict: debug,
    plugins: debug ? [createLogger()] : [],
    modules: {
        auth: authModule,
        Credentials: credentialsModule,
        Categories: categoriesModule
    },

    state: {
        test: 'yes-yes'
    },
    getters: {
        getTest(state) {
            return state.test
        }
    }
})

export default Store