import axios from 'axios'
import store from '@/store'
import Router from '@/router'

let baseURL = 'https://sec-api.wayand.dk'

if (location.hostname === 'localhost') {
    baseURL = 'http://localhost:5000'
}

export const HTTP = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json'
    }
})

// HTTP.interceptors.response.use((response) => {
//     return response
// }, function (error) {
//     if (error.response.status === 404) {
//         //console.log('interceptors.response error: ', Router.currentRoute.value.path)
//         //console.log('interceptors.response error: ', Router.currentRoute.value.path.substring(1).split('/'))
//         //Router.push({ name: 'NotFound', params: { "catchAll": Router.currentRoute.value.path } })
//     }
//     return Promise.reject(error);
// });