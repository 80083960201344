import { HTTP } from '@/api/http'

class Categories {
    getAll() {
        return HTTP.get('/categories')
    }
    get(id) {
        return HTTP.get('/categories/' + id)
    }
    add(category) {
        return HTTP.post('/categories', category)
    }
    update(payload) {
        return HTTP.put('/categories/' + payload.id, payload.category)
    }
    remove(id) {
        return HTTP.delete('/categories/' + id)
    }
}

export default new Categories()