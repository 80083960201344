<template>
    <div class="modal fade show" id="exampleModalForm" tabindex="-1" role="dialog" aria-labelledby="exampleModalFormTitle" style="display: block;">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalFormTitle">Edit Category: {{ currentid }} </h5>
                    <button type="button" class="close" @click="$emit('close')" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>

                <div v-if="errorApi" class="alert alert-danger" role="alert">
                    <span v-for="(field, index) in errorApi" :key="index">
                        {{ field }}
                    </span>
                </div>

                <form @submit.prevent="saveForm">
                <div class="modal-body">

                    <div class="form-group row">
                        <div class="col-12 col-md-3 text-right">
                            <label>Name</label>
                        </div>
                        <div class="col-12 col-md-9">
                            <input v-model="name" type="text" class="form-control" :class="{ 'is-invalid': error.name || errorApi.name }" placeholder="Enter name">
                            <div v-if="error.name" class="invalid-feedback">{{ error.name }}</div>
                            <div v-if="errorApi.name" class="invalid-feedback">
                                <span v-for="(er, index) in errorApi.name" :key="index">
                                    {{ er }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-12 col-md-3 text-right">
                            <label>Logo</label>
                        </div>
                        <div class="col-12 col-md-9">
                            <input v-model="logo" type="text" class="form-control" :class="{ 'is-invalid': error.logo || errorApi.logo }" placeholder="Enter logo">
                            <div v-if="error.logo" class="invalid-feedback">{{ error.logo }}</div>
                            <div v-if="errorApi.logo" class="invalid-feedback">
                                <span v-for="(er, index) in errorApi.logo" :key="index">
                                    {{ er }}
                                </span>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger btn-pill"  @click="$emit('close')">Close</button>
                    <button type="submit" class="btn btn-primary btn-pill">Save Changes</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import validators from '@/utilities/validators'
export default {
    props: {
        id: { type: Number, required: true }
    },
    emits: ['close'],
    setup(props, {emit}) {
        const error = ref('')
        const errorApi = ref('')
        const store = useStore()
        onMounted( async () => await store.dispatch('fetchCategory', props.id))

        const name = computed({
            get: () => store.getters.getCategory.name,
            set: val => store.commit('updateCategory', { key: 'name', value: val})
        })

        const logo = computed({
            get: () => store.getters.getCategory.logo,
            set: val => store.commit('updateCategory', { key: 'logo', value: val})
        })

        const { isName, isEmpty } = validators()
        const validate = () => {
            let errors = {}
            let err
            if (err = isName('Name', name.value, 3, 100)) {
                errors['name'] = err
            }

            if (Object.keys(errors).length === 0 && errors.constructor === Object) {
                error.value = ''
                return true
            } else {
                error.value = errors
                return false
            }
        }

        return {
            name,
            logo,
            currentid: props.id,
            error,
            errorApi,
            saveForm: () => {
                if (validate()) {
                    console.log('saveForm')
                    store.dispatch('updateCategory', {id: props.id, category: {
                        name: name.value,
                        logo: logo.value
                    }})
                    .then(resp => {
                        emit('close')
                        toast.success('Category saved')
                    })
                    .catch(e => {
                        if (e.response) {
                            errorApi.value = e.response.data.errors || e.response.data.error
                        } else {
                            errorApi.value = [e.message]
                        }
                    })
                } else {
                    console.log('not valid, showing errors')
                }
            }
        }
    },
}
</script>