<template>
    <aside class="left-sidebar bg-sidebar">
        <div id="sidebar" class="sidebar sidebar-with-footer">
            <!-- Aplication Brand -->
            <div class="app-brand">
                <a href="/index.html">
                    <svg class="brand-icon" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid" width="30" height="33" viewBox="0 0 30 33">
                        <g fill="none" fill-rule="evenodd">
                        <path class="logo-fill-blue" fill="#7DBCFF" d="M0 4v25l8 4V0zM22 4v25l8 4V0z"></path>
                        <path class="logo-fill-white" fill="#FFF" d="M11 4v25l8 4V0z"></path>
                        </g>
                    </svg>
                    <span class="brand-name">Secrets</span>
                </a>
            </div>
            <!-- begin sidebar scrollbar -->
            <div class="slimScrollDiv" style="position: relative; overflow: hidden; width: auto; height: 100%;">
                <div class="sidebar-scrollbar" style="overflow: hidden; width: auto; height: 100%;">

                    <!-- sidebar menu -->
                    <ul class="nav sidebar-inner" id="sidebar-menu">
                        <li v-for="route in routes" :key="route.path" :class="{ active: isActive(route, route.to), 'has-sub': route.subMenu }">
                            <template v-if="!route.subMenu?.length">
                                <router-link class="sidenav-item-link" :to="route.to">
                                    <i class="mdi" :class="route.icon"></i>
                                    <span class="nav-text">{{route.text}}</span>
                                </router-link>
                            </template> 

                            <template v-else>
                                <a class="sidenav-item-link" :href="route.to" data-toggle="collapse" :data-target="'#'+route.id" aria-expanded="false" :aria-controls="route.id">
                                    <i class="mdi" :class="route.icon"></i>
                                    <span class="nav-text">{{ route.text }}</span>
                                    <b class="caret"></b>
                                </a>

                                <ul class="collapse" :id="route.id" data-parent="#sidebar-menu" style="">
                                    <div class="sub-menu">
                                        <li v-for="subRoute in route.subMenu" :key="subRoute.path" :class="{ active: isActive(subRoute, subRoute.to)}">
                                            <router-link class="sidenav-item-link" :to="subRoute.to">
                                                <span class="nav-text">{{ subRoute.text }}</span>
                                            </router-link>
                                        </li>
                                    </div>
                                </ul>
                            </template>
                        </li>
                    </ul>

                </div>
                <div class="slimScrollBar" style="background: rgb(128, 128, 128); width: 5px; position: absolute; top: 0px; opacity: 0.5; display: none; border-radius: 7px; z-index: 99; right: 1px; height: 669.594px;"></div>
                <div class="slimScrollRail" style="width: 5px; height: 100%; position: absolute; top: 0px; display: none; border-radius: 7px; background: rgb(51, 51, 51); opacity: 0.2; z-index: 90; right: 1px;"></div>
            </div>

            <hr class="separator">

            <div class="sidebar-footer">
                <div class="sidebar-footer-content">
                    <h6 class="text-uppercase">
                        Cpu Uses <span class="float-right">40%</span>
                    </h6>
                    <div class="progress progress-xs">
                        <div class="progress-bar active" style="width: 40%;" role="progressbar"></div>
                    </div>
                    <h6 class="text-uppercase">
                        Memory Uses <span class="float-right">65%</span>
                    </h6>
                    <div class="progress progress-xs">
                        <div class="progress-bar progress-bar-warning" style="width: 65%;" role="progressbar"></div>
                    </div>
                </div>
            </div>
        </div>
    </aside>
</template>
<script>
import { useRouter } from 'vue-router'
import { computed } from 'vue'
export default {
    setup () {
        const router = useRouter()
        const activeRoute = computed(() => router.currentRoute.value.path)
        const routes = [
            { to: '/credentials', text: 'Credentials', icon: 'mdi-security-lock' },
            { to: '/import-secrets', text: 'Import secrets', icon: 'mdi-import' },
            { to: 'javascript:void(0)', id: 'settings-submenu', text: 'settings', icon: 'mdi-security-lock', subMenu: [
                    { to: '/settings/categories', text: 'Categories', icon: 'mdi-security-lock' },
                ]
            }
        ]
        const isActive = (route, to) => {
            if (route.subMenu) {
                const path = route.subMenu.filter(route => activeRoute.value === route.to)[0]?.to
                return activeRoute.value.startsWith(path)
            } else {
                return to === activeRoute.value
            }
        }
        return {
            isActive,
            routes
        }
    }
}
</script>
