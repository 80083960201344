import Auth from '@/api/auth'
import { HTTP } from '@/api/http'

const authModule = {
	state: {
		user: JSON.parse(localStorage.getItem('user')) || null,
		layout: localStorage.getItem('layout') || 'login-layout'
	},

	getters: {
		isAuthenticated: (state) => !!state.user,
		layout: (state) => state.layout,
		username: (state) => state.user?.name
	},

	mutations: {
		setUserData (state, userData) {
			state.user = userData
			localStorage.setItem('user', JSON.stringify(userData))
			HTTP.defaults.headers.common['Authorization'] = `Bearer ${
				userData.access_token
			}`
		},
		clearUserData (state) {
			localStorage.removeItem('user')
			location.reload()
		},
		setLayout (state, layout) { 
			state.layout = layout
			localStorage.setItem('layout', layout)
		}
	},

	actions: {
		login({commit}, credentials) {
			return Auth.login(credentials)		
			.then( (resp) => {
				console.log('Congratulationssss. Login')
				commit('setUserData', resp.data)
				commit('setLayout', 'app-layout')
				return resp
			})	
		},

		logout({commit}) {
			return Auth.logout()
			.then((resp) => {
				console.log('Congratulationssss. Logout')
				commit('clearUserData')
				commit('setLayout', 'login-layout')
			})	
		},

		isAuthorized({commit}) {
			return Auth.isAuthorized().catch(err=>err)
		}
	}
}

export default authModule
