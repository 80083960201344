<template>
    <div class="card card-default">
        <div class="card-header card-header-border-bottom">
            <h2>Import Secrets</h2>
        </div>
        <div class="card-body">
            <form @submit.prevent="handleSubmit">
                <div v-if="error.length" class="alert alert-danger" role="alert">
                    <div v-for="(e, index) in error" :key="index">
                        {{ e }}
                    </div>
                </div>
                <div v-if="success.length">
                    <div class="alert alert-success" role="alert">
                        <div v-for="(e, index) in success" :key="index">
                            {{ e }}
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label for="exampleFormControlFile1">Example file format:</label>

<pre><code>
    [
        {
            "category": "1",
            "created_at": "2021-07-26T10:15:04",
            "name": "sentry javascript errors",
            "note": "",
            "password": "dzspwd@!0",
            "updated_at": null,
            "url": "https://sentry.com",
            "user_uuid": "572ed983-4092-4ca5-9803-6eac03403a35",
            "username": "lawangjan@hotmail.com",
            "uuid": "80b621fe-f52e-4124-a1ec-39c84b1e6c1c"
        },
        ...
    ]
</code></pre>

                    <input type="file" @change="uploadFile" class="form-control-file" >
                </div>
                <div class="form-footer pt-4 pt-5 mt-4 border-top">
                    <button :disabled="noFiles" type="submit" class="btn btn-primary btn-default mr-2">Submit</button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import { inject, ref } from 'vue'
import { useStore } from 'vuex'
export default {
    setup() {
        const file = ref('')
        const error = ref([])
        const success = ref([])
        const noFiles = ref(1)

        const store = useStore()
        const toast = inject('toast')

        const uploadFile = (e) => {
            file.value = e.target.files[0]
            noFiles.value = e.target.files.length ? 0 : 1
        }

        const handleSubmit = () => {

            error.value = []
            success.value = []

            const formData = new FormData()
            formData.append('file', file.value)

            if (file.value) {
                store.dispatch('importFromFile', formData)
                    .then((resp) => {
                        console.log('Uploaded: ', resp.data.message)
                        toast.success(resp.data.message)
                        success.value = resp.data.success
                        error.value = resp.data.unique_errors
                    })
                    .catch((e) => {
                        if (e.response) {
                            console.log('Upload Failed: ', e.response.data.error)
                            toast.error(e.response.data.error)
                        } else {
                            console.log('Something went wrong: ', e.message)
                            toast.error(e.message)
                        }
                        error.value.push(e.message)
                    })
                    .finally(() => {
                        file.value = ''
                        noFiles.value = 1
                        document.forms[0].reset()
                    })
            } else {
                toast.error('No File to upload')
            }
        }

        return {
            uploadFile,
            handleSubmit,
            error,
            success,
            noFiles
        }
    },
}
</script>
<style scoped>
button:disabled {
    cursor: not-allowed;
}
pre code {
    font-size: inherit;
}
</style>