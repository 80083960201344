<template>
    <div class="card card-default">
        <div class="card-header card-header-border-bottom">
            <h2>Categories</h2>
        </div>
        <div class="card-body">

            <div v-if="error" class="alert alert-danger" role="alert">
                <span v-for="(field, index) in error" :key="index">
                    {{ field }}
                </span>
            </div>

            <div class="input-group">
                <input v-model="categoryName" type="text" class="form-control" placeholder="add category..." aria-label="add">
                <div class="input-group-append save" @click="saveCategory">
                    <span class="input-group-text bg-primary"> Save </span>
                </div>
            </div>
            
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="category in getCategories" :key="category.id">
                            <td scope="row">{{ category.id }}</td>
                            <td>{{ category.name }}</td>
                            <td>
                                <div class="btn-group" role="group" aria-label="Basic example">
                                    <button @click="editCat(category.id)" type="button" class="btn btn-outline-primary p-1"><i class="mdi mdi-square-edit-outline mdi-18px"></i></button>
                                    <button @click="deleteCat(category)" type="button" class="btn btn-outline-danger p-1"><i class="mdi mdi-delete-outline mdi-18px"></i></button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <teleport to="body">
        <edit-category :id="currentEdittingID" v-if="showEditCategoryModal" @close="closeEditCategoryModal" />
    </teleport>
</template>
<script>
import { inject, ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import EditCategory from '@/components/EditCategory'
import validators from '@/utilities/validators'
export default {
    components: {
        EditCategory
    },
    setup() {

        const toast = inject('toast')
        const store = useStore()
        const showEditCategoryModal = ref('')
        const categoryName = ref('')
        const currentEdittingID = ref(0)
        const getCategories = computed(() => {
            return store.getters.getCategories()
        })
        const error = ref('')

        const { isName, isEmpty } = validators()
        const validate = () => {
            let errors = {}
            let err

            if (err = isName('Category name', categoryName.value, 3, 100)) {
                errors['name'] = err
            }

            if (Object.keys(errors).length === 0 && errors.constructor === Object) {
                error.value = ''
                return true
            } else {
                error.value = errors
                return false
            }
        }

        const saveCategory = () => {
            if (validate()) {
                store.dispatch('addCategory', {
                    name: categoryName.value
                })
                .then(resp => {
                    toast.success('Category Added')
                })
                .catch(e => {
                    if (e.response) {
                        error.value = e.response.data.errors || e.response.data.error
                    } else {
                        error.value = [e.message]
                    }
                })
                .finally(() => {
                    categoryName.value = ''
                })
                
            } else {
                console.log('not valid, showing errors')
            }
        }
        const openEditCategoryModal = () => {
            document.body.classList.add("modal-open")
            showEditCategoryModal.value = true
        }
        onMounted(async () => await store.dispatch('fetchCategories'))
        return {
            error,
            currentEdittingID,
            categoryName,
            saveCategory,
            getCategories: getCategories,
            editCat: id => {
                currentEdittingID.value = id
                openEditCategoryModal()
            },
            deleteCat: cat => {
                console.log(`Deleting category no. ${cat.id}`)
                if ( confirm('This will permanently delete the "'+ cat.name +'" from your Vault.') ) {
                    
                    store.dispatch('removeCategory', cat.id)
                        .then(() => {
                            toast.success('Successfully Deleted', {
                                position: "top",
                                duration: 3000
                            })
                        })
                        .catch((e) => {
                            console.log('Failed to delete category: ', e.message)
                            if (e.response) {
                                error.value = e.response.data.errors || e.response.data.error
                            } else {
                                error.value = [e.message]
                            }
                        })
                }
            },
            showEditCategoryModal,
            openEditCategoryModal,
            closeEditCategoryModal: (e) => {
                document.body.classList.remove("modal-open")
                showEditCategoryModal.value = false
            },
        }
    },
}
</script>
<style scoped>
.input-group-append.save {
    cursor: pointer;
}
</style>