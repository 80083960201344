
export default function validators() {
    const isEmpty = (fieldName, fieldValue) => {
        return !fieldValue ? `The ${fieldName} field is required` : ''
    }

    const minLength = (fieldName, fieldValue, min) => {
        return fieldValue.length < min ? `The ${fieldName} field must be atleast ${min} characters long` : ''
    }

    const maxLength = (fieldName, fieldValue, max) => {
        return fieldValue.length > max ? `The ${fieldName} field must be max ${max} characters long` : ''
    }

    const isEmail = (fieldName, fieldValue) => {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !re.test(fieldValue) ? `The input is not a valid ${fieldName} address` : ''
    }

    const isNum = (fieldName, fieldValue) => {
        let num = /^\d+$/.test(fieldValue)
        return !num ? `The ${fieldName} field only have numbers` : ''
    }

    const isPhone = (fieldName, fieldValue) => {
        return !fieldValue ? isEmpty(fieldName, fieldValue) : isNum(fieldName, fieldValue)
    }

    const isName = (fieldName, fieldValue, min, max) => {
        return !fieldValue ? isEmpty(fieldName, fieldValue) : ( 
            minLength(fieldName, fieldValue, min) || maxLength(fieldName, fieldValue, max)
        )
    }
    const isPassword = (fieldName, fieldValue, min, max) => {
        return !fieldValue ? isEmpty(fieldName, fieldValue) : ( 
            minLength(fieldName, fieldValue, min) || maxLength(fieldName, fieldValue, max)
        )
    }

    return { isEmpty, minLength, maxLength, isEmail, isNum, isPhone, isName, isPassword }
}