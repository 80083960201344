<template>
    <div class="card card-default">
        <div class="card-header card-header-border-bottom">
            <h2>Credentials</h2>
        </div>
        <div class="card-body">
            
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text px-2 py-1 set-sort-dir" :class="{'sort-dir-active': sortDirection == 'asc'}" @click="setSortDirection('asc')" title="asc">
                        <i class="mdi mdi-sort-ascending mdi-18px"></i>
                    </span>
                    <span class="input-group-text px-2 py-1 set-sort-dir" :class="{'sort-dir-active': sortDirection == 'desc'}" @click="setSortDirection('desc')" title="desc">
                        <i class="mdi mdi-sort-descending mdi-18px"></i>
                    </span>
                </div>
                <input v-model="filterKey" type="text" class="form-control" placeholder="Search to filter..." aria-label="search">
                <div class="input-group-append">
                    <span class="input-group-text bg-primary"> Filter! </span>
                </div>
            </div>
            
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Category</th>
                            <th scope="col">Last updated</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in credentials" :key="index"
                            @mouseover="goToWebsite"
                            @mouseleave="goToWebsite"
                            @click="$router.push({ name: 'CredentialDetails', params: { uuid: item.uuid } })"
                        >
                            <td scope="row">{{ ++index }}</td>
                            <td>{{ item.name }}<br>{{ item.username }}</td>
                            <td>{{ getCategoryById(item.category_id).name }}</td>
                            <td :title="formatDate(item.updated_at)">{{ relativeDate(item.updated_at) }}</td>
                            <td class="text-right" style="width: 160px">
                                <div class="goToWebsite hidden rounded border border-secondary mr-4">
                                    <span @click="go(item.url)" class="mdi mdi-export mdi-24px p-4"></span>
                                </div>
                                <div class="dropdown d-inline-block widget-dropdown">
                                    <a class="dropdown-toggle icon-burger-mini" href="" role="button" id="dropdown-recent-order1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static"></a>
                                    <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown-recent-order1">
                                        <li class="dropdown-item">
                                            <router-link :to="{ name: 'CredentialDetails', params: { uuid: item.uuid } }">See details</router-link>
                                        </li>
                                        <li class="dropdown-item">
                                            <a @click="copyToClipboard(item.password, $event)" href="javascript:void(0)">Copy Password</a>
                                        </li>
                                        <li class="dropdown-item">
                                            <a @click="copyToClipboard(item.username, $event)" href="javascript:void(0)">Copy login</a>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import { inject, ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import MyDatetimeObject from '@/utilities/mydate'

export default {
    setup () {
        const store = useStore()
        const toast = inject('toast');
        const filterKey = ref('')
        const sortDirection = ref('desc')

        const copyToClipboard = (txt) => {
            var copyTest = document.queryCommandSupported('copy');
            if (copyTest) {
                var copyTextArea = document.createElement("textarea");
                copyTextArea.value = txt
                document.body.appendChild(copyTextArea)
                copyTextArea.select()

                try {
                    if (document.execCommand('copy')) {
                        toast.info('Copied to clipboard!!')
                    } else { console.log('couldn\'t copy to clipboard !!') }
                }  catch (e) {
                    console.log('failed copy to clipboard: ', e.message)
                } finally {
                    document.body.removeChild(copyTextArea)
                }
            }
        }

        const goToWebsite = (e)=> { 
            const el = e.currentTarget.querySelector('td:last-child .goToWebsite')

            if (el && e.type === 'mouseover') {
                el.classList.remove('hidden')
                el.classList.add('d-inline-block')
            }
            if (el && e.type === 'mouseleave') {
                el.classList.add('hidden')
                el.classList.remove('d-inline-block')
            }
        }
        const go = url => window.open(url)
        onMounted(async () => {
            await store.dispatch('fetchCategories')
            await store.dispatch('fetchCredentials')
        })

        return {
            sortDirection,
            setSortDirection: dir => sortDirection.value = dir,
            getCategoryById: (id) => store.getters.getCategoryById(id),
            credentials: computed(() => {
                return store.getters.getCredentials
                    .filter((item) => item.name.toLowerCase().includes(filterKey.value.toLowerCase()))
                    .sort((a, b) => {
                        const direction = sortDirection.value === 'asc' ? 1 : -1
                        a = new Date(a.created_at)
                        b = new Date(b.created_at)
                        const results = a > b ? -1 : a < b ? 1 : 0
                        return results * direction
                    })
            }),
            goToWebsite,
            go,
            filterKey,
            copyToClipboard,
            relativeDate: dateStr => {
                return dateStr ? MyDatetimeObject().relativeDate(dateStr) : "Never"
            },
            formatDate: dateStr => MyDatetimeObject().toLocaleString(dateStr)
        }
    }
}
</script>
<style scoped>
.table th {
    border-top: none;
    border-bottom: 1px solid #dee2e6
}
.table tbody tr {
    cursor: pointer;
}
.table td {
    border-bottom: 1px solid #dee2e6
}
.hidden {
    display: none;
}
.goToWebsite, .set-sort-dir {
    cursor: pointer;
}
.sort-dir-active {
    background-color: black !important;
    color: white;
}
</style>
