<template>
    <footer class="footer mt-auto">
        <div class="copyright bg-white">
            <p>
            &copy; <span id="copy-year">{{year}}</span> Copyright Sleek Dashboard Bootstrap Template by
            <a
                class="text-primary"
                href="http://www.iamabdus.com/"
                target="_blank"
                >Abdus</a
            >.
            </p>
        </div>
    </footer>
</template>
<script>
import { ref } from 'vue'
export default {
  setup () {
    const year = ref(false)
    const d = new Date()
    year.value = d.getFullYear()

    return {
      year
    }
  }
}
</script>
