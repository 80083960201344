<template>    
    <div class="modal fade show" id="exampleModalForm" tabindex="-1" role="dialog" aria-labelledby="exampleModalFormTitle" style="display: block;">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalFormTitle">Add new Secret</h5>
                    <button type="button" class="close" @click="$emit('close')" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>

                <div v-if="errorApi" class="alert alert-danger" role="alert">
                    <span v-for="(field, index) in errorApi" :key="index">
                        {{ field }}
                    </span>
                </div>

                <form @submit.prevent="saveForm">
                <div class="modal-body">
                    <div class="form-group row">
                        <div class="col-12 col-md-3 text-right">
                            <label>URL</label>
                        </div>
                        <div class="col-12 col-md-9">
                            <input v-model="url" type="text" class="form-control" :class="{ 'is-invalid': error.url || errorApi.url }" placeholder="Enter url">
                            <small class="form-text text-muted">(https://google.com).</small>
                            <div v-if="error.url" class="invalid-feedback">{{ error.url }}</div>
                            <div v-if="errorApi.url" class="invalid-feedback">
                                <span v-for="(er, index) in errorApi.url" :key="index">
                                    {{ er }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-12 col-md-3 text-right">
                            <label>Name</label>
                        </div>
                        <div class="col-12 col-md-9">
                            <input v-model="name" type="text" class="form-control" :class="{ 'is-invalid': error.name || errorApi.name }" placeholder="Enter name">
                            <div v-if="error.name" class="invalid-feedback">{{ error.name }}</div>
                            <div v-if="errorApi.name" class="invalid-feedback">
                                <span v-for="(er, index) in errorApi.name" :key="index">
                                    {{ er }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-12 col-md-3 text-right">
                            <label>Category</label>
                        </div>
                        <div class="col-12 col-md-9">
                            <select class="form-control" id="exampleFormControlSelect12" v-model="category_id">
                                <option v-for="category in getCategories" :key="category.id" :value="category.id">{{category.name}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-12 col-md-3 text-right">
                            <label>Username</label>
                        </div>
                        <div class="col-12 col-md-9">
                            <input v-model="username" type="text" class="form-control" :class="{ 'is-invalid': error.username || errorApi.username }" placeholder="Enter username">
                            <div v-if="error.username" class="invalid-feedback">{{ error.username }}</div>
                            <div v-if="errorApi.username" class="invalid-feedback">
                                <span v-for="(er, index) in errorApi.username" :key="index">
                                    {{ er }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-12 col-md-3 text-right">
                            <label>Password</label>
                        </div>
                        <div class="input-group col-12 col-md-9">
                            <input v-model="password" :type="PasswordFieldType" class="form-control" :class="{ 'is-invalid': error.password || errorApi.password }" placeholder="Enter password">
                            <div class="input-group-append">
                                <span class="input-group-text cursor-pointer" @click="switchVisibility">
                                    <i class="mdi mdi-eye"></i>
                                </span>
                            </div>
                            <div v-if="error.password" class="invalid-feedback">{{ error.password }}</div>
                            <div v-if="errorApi.password" class="invalid-feedback">
                                <span v-for="(er, index) in errorApi.password" :key="index">
                                    {{ er }}
                                </span>
                            </div>
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-12 col-md-3 text-right">
                            <label>Note</label>
                        </div>
                        <div class="col-12 col-md-9">
                            <textarea v-model="note" class="form-control" rows="3"></textarea>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger btn-pill"  @click="$emit('close')">Close</button>
                    <button type="submit" class="btn btn-primary btn-pill">Save Changes</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import validators from '@/utilities/validators'

export default {
    emits: ['close'],
    setup(props, {emit}) {
        const store = useStore()
        const router = useRouter()
        const route = useRoute()

        let url = ref('')
        let name = ref('')
        let category_id = ref(1)
        let username = ref('')
        let password = ref('')
        let note = ref('')
        let PasswordFieldType = ref('password')

        const error = ref('')
        const errorApi = ref('')

        const getCategories = computed(() => {
            return store.getters.getCategories()
        })

        onMounted(async () => await store.dispatch('fetchCategories'))

        const { isPassword, isEmail, isName, isEmpty } = validators()
        const validate = () => {
            let errors = {}
            let err
            if (err = isEmpty('Url', url.value)) {
                errors['url'] = err
            }
            if (err = isName('Name', name.value, 3, 100)) {
                errors['name'] = err
            }
            if (err = isName('Username', username.value, 5, 100)) {
                errors['username'] = err
            }
            if (err = isPassword('Password', password.value, 5, 100)) {
                errors['password'] = err
            }

            if (Object.keys(errors).length === 0 && errors.constructor === Object) {
                error.value = ''
                return true
            } else {
                error.value = errors
                return false
            }
        }

        const saveForm = () => {
            if (validate()) {
                store.dispatch('addCredential', {
                    url: url.value.indexOf('http') > -1 ? url.value : 'https://' + url.value,
                    name: name.value,
                    category_id: category_id.value,
                    username: username.value,
                    password: password.value,
                    note: note.value
                })
                .then((resp) => {
                    emit('close')
                    if (route.name != 'Credentials') {
                        router.push({ name: "Credentials" })
                    }
                })
                .catch((e) => {
                    if (e.response) {
                        errorApi.value = e.response.data.errors || e.response.data.error
                    } else {
                        errorApi.value = [e.message]
                    }
                })
            } else {
                console.log('not valid, showing errors')
            }
        }

        return {
            getCategories,
            error,
            errorApi,
            url,
            name,
            category_id,
            username,
            password,
            note,
            saveForm,
            PasswordFieldType,
            switchVisibility: () => PasswordFieldType.value = PasswordFieldType.value === 'password' ? 'text' : 'password'
        }
    }
}
</script>
<style scoped>
::v-slotted(p) {
    font-style: italic;
}
.cursor-pointer {
    cursor: pointer;
}
</style>
