import { createApp } from 'vue'
import App from './App'
import router from './router'
import store from './store'
import { HTTP } from '@/api/http'
import Toaster from "@meforma/vue-toaster"

const token = JSON.parse(localStorage.getItem('user')) || null
if (token) {
    HTTP.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token
}

const app = createApp(App)
app.config.devtools = true
app.use(store)
app.use(router)
app.use(Toaster, { position: "top-right" }).provide('toast', app.config.globalProperties.$toast);
app.mount('#app')
