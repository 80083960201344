import { createRouter, createWebHistory } from 'vue-router'
import ImportSecrets from '../views/ImportSecrets'
import Credentials from '../views/Credentials'
import SettingCategoires from '../views/settings/Categoires'
import CredentialDetails from '../views/CredentialDetails'
import Login from '../views/Login'
import NotFound from '../views/NotFound'
import store from '../store'

const routes = [
    {
        path: '/',
        name: 'Home',
        redirect: 'credentials'
    },
    {
        path: '/import-secrets',
        name: 'ImportSecrets',
        component: ImportSecrets,
        meta: { requiresAuth: true, title: 'Import Secrets' }
    },
    {
        path: '/credentials',
        name: 'Credentials',
        component: Credentials,
        meta: { requiresAuth: true, title: 'Credentials' }
    },
    {
        path: '/credentials/:uuid',
        name: 'CredentialDetails',
        component: CredentialDetails,
        props: true,
        meta: { requiresAuth: true, title: 'Credential Details' }
    },
    {
        path: '/settings/categories',
        name: 'SettingCategoires',
        component: SettingCategoires,
        meta: { requiresAuth: true, title: 'Settings: Categories' }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: { title: 'Login'}
    },
    {
        path: "/:pathMatch(.*)*",
        name: 'NotFound',
        component: NotFound,
        meta: { title: 'Not Found'}
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

const localLogout = ({from, to})=> {
    store.commit('setLayout', 'login-layout')
}
const setPageMetaFields = (to) => {
    window.document.title = to.meta.title
}

router.beforeEach(async (to, from, next) => {
    const isAuth = await store.dispatch('isAuthorized').catch((e)=>{console.log('catched from router.beforeEach: ',e.message)})
    const authStatus = isAuth?.response?.status || (isAuth?.status || 400)

    setPageMetaFields(to)

    if (to.matched.some(record => record.meta.requiresAuth) && authStatus !== 200 && to.path != '/login' ) {
        localLogout({from:from.path, to:to.path})
        next({
            path: '/login',
            query: { redirect: to.path }
        })
        return
    } else if (authStatus === 200 && to.path == '/login') {
        next('/')
        return
    }
    next()
})

export default router
